import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import * as historyCategoryConsts from "../../../history-categories-consts"
import * as statusesConsts from "../../../status-consts";
import classes from "../../../assets/styles/Task.module.css";


export interface HistoryProps {
  taskDetails: any;
  historyCategoriesList: any

}

const History = (props: HistoryProps) => {
  const { taskDetails, historyCategoriesList } = props;

  return (
    <Grid item container style={{ overflowY: 'auto', maxHeight: '300px' }} rowGap={3} >
      {taskDetails?.history?.map((i: any, index: any) => (
        <Grid item container direction='column' key={index} rowGap={1}>
          <Grid item container direction='row' columnGap={2}>
            {i.category === historyCategoryConsts.CREATION_CATEGORY_ID ?
              <>
                <Grid item className='labels-extra-bold'>{i.creator} <span className='labels'>&nbsp;&nbsp;created the task on&nbsp;&nbsp; </span> </Grid>
                <Grid item className='neutral-3'>{i.creationDate}</Grid></>
              :
              <>
                <Grid item className='labels-extra-bold'>{i.creator} <span className='labels'>&nbsp;&nbsp;updated the&nbsp;&nbsp; </span> {historyCategoriesList?.find((c: any) => c.id === i.category)?.name} </Grid>
                <Grid item className='neutral-3'>{i.creationDate}</Grid></>
            }

          </Grid>
          <Grid item container>
            {i.category !== historyCategoryConsts.CREATION_CATEGORY_ID ?
              historyCategoryConsts.STATUS_CATEGORY_ID ?
                <Grid item container direction='row' columnGap={2} alignItems='center'>
                  <Grid item
                    className={classes[
                      i.previousValue === statusesConsts.NEW_STATUS_NAME ? 'new-task' :
                        i.previousValue === statusesConsts.OPEN_STATUS_NAME ? 'open-task' :
                          i.previousValue === statusesConsts.OVERDUE_STATUS_NAME ? 'overdue-task' :
                            i.previousValue === statusesConsts.IN_PROGRESS_STATUS_NAME ? 'inprogress-task' :
                              i.previousValue === statusesConsts.WAITING_FOR_OTHERS_STATUS_NAME ? 'completed-task' :
                                i.previousValue === statusesConsts.CLOSED_STATUS_NAME ? 'closed-task' : ''
                    ]}
                  >
                    {i.previousValue}
                  </Grid>
                  <Grid item>to</Grid>
                  <Grid item
                    className={classes[
                      i.nextValue === statusesConsts.NEW_STATUS_NAME ? 'new-task' :
                        i.nextValue === statusesConsts.OPEN_STATUS_NAME ? 'open-task' :
                          i.nextValue === statusesConsts.OVERDUE_STATUS_NAME ? 'overdue-task' :
                            i.nextValue === statusesConsts.IN_PROGRESS_STATUS_NAME ? 'inprogress-task' :
                              i.nextValue === statusesConsts.WAITING_FOR_OTHERS_STATUS_NAME ? 'completed-task' :
                                i.nextValue === statusesConsts.CLOSED_STATUS_NAME ? 'closed-task' : ''
                    ]}
                  >
                    {i.nextValue}
                  </Grid>
                </Grid>
                :
                <Grid item> {i.previousValue}&nbsp;&nbsp;&nbsp; to &nbsp;&nbsp;&nbsp; {i.nextValue}</Grid>
              :
              ""
            }
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default History