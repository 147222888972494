import * as apiConst from "../taxmation-consts";
import axios from "axios";


const GET_TASK_LIST = apiConst.GET_TASK_LIST;
export const getTaskList = (listData: object) => {
    return axios
        .post(`${process.env.REACT_APP_URL + GET_TASK_LIST}`,
            listData,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response.data;
            console.log(response.data);
        })
        .catch((error) => {
            return error.response.data
        })
}


const ADD_TASK = apiConst.ADD_TASK;
export const addTask = (task: {}) => {
    return axios
        .post(`${process.env.REACT_APP_URL + ADD_TASK}`, task,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const GET_TASK_INPUT_DATA = apiConst.GET_TASK_INPUT_DATA;
export const getTaskInputData = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + GET_TASK_INPUT_DATA}`,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const GET_TASK= apiConst.GET_TASK;
export const getTaskById = (taskId:string)=>{
    return axios
    .get(`${process.env.REACT_APP_URL + GET_TASK}?id=${taskId}`,
        {
            withCredentials:true,
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    .then((response)=>{
        return response.data;
    })
    .catch((error)=>{
        return error.response.data;
    })
}

const UPDATE_TASK= apiConst.UPDATE_TASK;
export const updateTask=(taskData:object)=>{
    return axios
    .put(`${process.env.REACT_APP_URL + UPDATE_TASK}`,taskData,
        {
            withCredentials:true,
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )
    .then((response)=>{
        return response.data;
    })
    .catch((error)=>{
        return error.response.data;
    })
}

const DELETE_TASK = apiConst.DELETE_TASK;
export const deleteTask = (taskId: string) => {
    return axios
        .delete(`${process.env.REACT_APP_URL + DELETE_TASK}?id=${taskId}`,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}