import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import classes from "../Admin/assets/styles/Profile.module.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as userServices from "../services/user-services.proxy";
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { getDialogLoadingState, loadingActions, setDialogLoading } from '../app/slices/loadingSlice';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Message from '../sharedComponents/ui/Message';

const getUserProfile = userServices.getUserProfile;
const updateUserProfile = userServices.updateUserProfile;

const EditProfile = ({ modifyProfile, setModifyProfile }: { modifyProfile: any, setModifyProfile: any }) => {

  const token = useAppSelector((state) => state.userData.token);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.loading.value);
  const dialogLoading = useAppSelector(getDialogLoadingState);

  const [activeTab, setActiveTab] = useState<string>('Account Information');
  const [userId, setUserId] = useState<string>('');

  const [username, setUserName] = useState<string>('');
  const [userNameError, setUserNameError] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');

  const [firstName, setFirstName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<boolean>(false);

  const [lastName, setLastName] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<boolean>(false);

  const [phoneNb, setPhoneNb] = useState<string>('');
  const [phoneNbError, setPhoneNbError] = useState<boolean>(false);

  const [position, setPosition] = useState<string>('');
  const [positionError, setPositionError] = useState<boolean>(false);

  const [callStatus, setCallStatus] = useState<string>('');
  const [BeErrorMsg, setBeErrorMsg] = useState<string>('');

  const navigate = useNavigate();

  const userData = {
    username: firstName + " " + lastName,
    firstName: firstName,
    lastName: lastName,
    phoneNumber: phoneNb,
    position: position
  }

  useEffect(() => {
    getUserProfileCall();
  }, []);

  const getUserProfileCall = () => {
    dispatch(setDialogLoading(true))
    getUserProfile(token).then((x) => {
      setUserName(x.username);
      setEmail(x.email);
      setFirstName(x.firstName);
      setLastName(x.lastName);
      setPosition(x.position);
      setPhoneNb(x.phoneNumber)
      dispatch(setDialogLoading(false))
    })
  }

  const updateUserProfileCall = () => {
    dispatch(loadingActions.setLoading(true));
    updateUserProfile(token, userData).then((x) => {
      if (x.status) {
        setCallStatus('Fail');
        setBeErrorMsg(x.title)
      }
      else {
        setCallStatus('Pass');
        getUserProfileCall();
        setModifyProfile(!modifyProfile)
      }
      setTimeout(() => {
        setCallStatus('');
        setBeErrorMsg('');
      }, 4500)
      dispatch(loadingActions.setLoading(false));

    })
  }

  return (
    <Grid item container direction='column' rowGap={1}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dialogLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {localStorage.getItem('isCMS') === 'false' ?
        <ArrowBackIcon
          onMouseOver={(e) => {
            const target = e.target as HTMLElement;
            target.style.cursor = 'pointer'
          }}
          onClick={(e) => {
            navigate('/dashboard')
          }}
        />
        :
        <></>
      }

      <Grid item className='desktop-header-5'><Message id="user.profile.account-management" className='desktop-header-5' /></Grid>
      <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} >
        <HomeOutlinedIcon /> <Message id="user.profile.home" style={{ color: '#93919A' }} /> / <Message id="user.profile.account-management" style={{ color: '#93919A' }} /> / <Message id="user.profile.edit-profile" style={{ color: '#93919A' }} />
        <Grid item container marginTop={2}>
          <Grid item className={classes[activeTab === 'Account Information' ? 'activeTabs' : 'tabs']}>
            <Message id="user.account-information" className='Field-input font-weight-700 labelsLinks' />
          </Grid>
          <Grid item container className={classes['profileContainer']} rowGap={2} justifyContent='space-between'>
            <Grid item xs={12} className='neutral-9 desktop-header-6'><Message id="user.profile.personal-details" className='neutral-9 desktop-header-6' /></Grid>
            <Grid item xs={0.6}>
              <AccountCircleIcon style={{ width: '70px', height: "70px" }} />
            </Grid>
            <Grid item container direction='column' xs={5}>
              <Grid item className='labels neutral-9'><Message id="user.profile.user-name" className='labels neutral-9' /></Grid>
              <Grid item>
                <TextField
                  disabled
                  value={username}
                  fullWidth
                  sx={{
                    '& label.Mui-focused': {
                      color: '#C3C2C7',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#B2BAC2',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#C3C2C7',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B2BAC2',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                      borderTopLeftRadius: '10px',
                      backgroundColor: '#F7F9FF',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container direction='column' xs={6}>
              <Grid item className='labels neutral-9'>
                <Message
                  id="user.profile.email"
                  className='labels neutral-9'
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled
                  value={email}
                  fullWidth
                  sx={{
                    '& label.Mui-focused': {
                      color: '#C3C2C7',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#B2BAC2',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#C3C2C7',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B2BAC2',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                      borderTopLeftRadius: '10px',
                      backgroundColor: '#F7F9FF',
                    },
                  }} />
              </Grid>
            </Grid>
            <Grid item container direction='column' xs={2.8}>
              <Grid item className='labels neutral-9'><Message id="user.profile.first-name" className='labels neutral-9' /></Grid>
              <Grid item>
                <TextField
                  fullWidth
                  value={firstName}
                  error={firstNameError}
                  helperText={firstNameError ? 'first name is required' : ''}
                  sx={{
                    '& label.Mui-focused': {
                      color: '#C3C2C7',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#B2BAC2',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#C3C2C7',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B2BAC2',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                      borderTopLeftRadius: '10px',
                      backgroundColor: '#F7F9FF',
                    },
                  }}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setUserName(e.target.value + ' ' + lastName)
                    if (!e.target.value) {
                      setFirstNameError(true);
                    }
                    else {
                      setFirstNameError(false);
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setUserNameError(true);
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container direction='column' xs={2.8}>
              <Grid item className='labels neutral-9'><Message id="user.profile.last-name" className='labels neutral-9' /></Grid>
              <Grid item>
                <TextField
                  value={lastName}
                  error={lastNameError}
                  helperText={lastNameError ? 'last name is required' : ''}
                  fullWidth
                  sx={{
                    '& label.Mui-focused': {
                      color: '#C3C2C7',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#B2BAC2',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#C3C2C7',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B2BAC2',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                      borderTopLeftRadius: '10px',
                      backgroundColor: '#F7F9FF',
                    },
                  }}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setUserName(firstName + ' ' + e.target.value)
                    if (!e.target.value) {
                      setLastNameError(true);
                    }
                    else {
                      setLastNameError(false);
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setLastNameError(true);
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container direction='column' xs={2.8}>
              <Grid item className='labels neutral-9'><Message id="user.profile.phone-number" className='labels neutral-9' /></Grid>
              <Grid item>
                <PhoneInput
                  country={'lb'}
                  placeholder="Enter phone number"
                  enableAreaCodes={true}
                  value={phoneNb}
                  onChange={(phone, country) => {
                    setPhoneNb(phone);
                    var countryCode = JSON.parse(JSON.stringify(country)).dialCode;
                    let phoneWithoutCode = phone.substring(countryCode.length, phone.length)
                    if (phoneWithoutCode === "" || phoneWithoutCode.length < 4 || phoneWithoutCode.length > 16) {
                      setPhoneNbError(true);
                    } else {
                      setPhoneNbError(false);
                    }
                  }}
                  inputStyle={{
                    borderRadius: '12px',
                    fontFamily: 'DM Sans',
                    height: '50px',
                    borderTopLeftRadius: '10px',
                    backgroundColor: '#F7F9FF',
                    width: '100%'
                  }}
                />
                {/* <TextField
                  value={phoneNb}
                  error={phoneNbError}
                  helperText={phoneNbError ? 'phone number is required' : ''}
                  fullWidth
                  sx={{
                    '& label.Mui-focused': {
                      color: '#C3C2C7',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#B2BAC2',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#C3C2C7',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B2BAC2',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                      borderTopLeftRadius: '10px',
                      backgroundColor: '#F7F9FF',
                    },
                  }}
                  onChange={(e) => {
                    setPhoneNb(e.target.value)
                    if (!e.target.value) {
                      setPhoneNbError(true);
                    }
                    else {
                      setPhoneNbError(false);
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setPhoneNbError(true);
                    }
                  }}
                /> */}
                {phoneNbError ? (
                  <span
                    style={{
                      fontFamily: "DM Sans",
                      color: "#D32F2F",
                      fontSize: "12px",
                    }}
                  >
                    Phone number must be between 7 and 15 digits
                  </span>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <Grid item container direction='column' xs={2.8}>
              <Grid item className='labels neutral-9'><Message id="user.profile.position" className='labels neutral-9' /></Grid>
              <Grid item>
                <TextField
                  value={position}
                  error={positionError}
                  helperText={positionError ? 'position is required' : ''}
                  fullWidth
                  sx={{
                    '& label.Mui-focused': {
                      color: '#C3C2C7',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#B2BAC2',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#C3C2C7',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B2BAC2',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                      borderTopLeftRadius: '10px',
                      backgroundColor: '#F7F9FF',
                    },
                  }}
                  onChange={(e) => {
                    setPosition(e.target.value)
                    if (!e.target.value) {
                      setPositionError(true);
                    }
                    else {
                      setPositionError(false);
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setPositionError(true);
                    }
                  }}
                /></Grid>
            </Grid>
            <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
              <Grid item xs={3}
                onClick={() => {
                  if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                  else navigate('/users')
                }}>
                <button className='greyButton'><Message id="button.cancel" className='labels-extra-bold Field-input labelsLinks' /></button>
              </Grid>
              <Grid item xs={3}>
                <button
                  className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
                  disabled={loading || firstNameError || lastNameError || positionError || phoneNbError}
                  onClick={() => updateUserProfileCall()}>
                  {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                    : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                      : callStatus === 'Fail' ? "Failed"
                        : <Message id="button.apply" className='labels-extra-bold Field-input labelsLinks' />}
                </button>
                <Grid item textAlign='center' className='errorText' paddingTop={1}>{BeErrorMsg}</Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>

      </Grid>
    </Grid>
  )
}

export default EditProfile
