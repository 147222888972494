import React, { useState, useEffect, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, Grid, TextField, Typography } from '@mui/material';
import { getDialogLoadingState, getLoadingState, setDialogLoading, setLoading } from '../../../app/slices/loadingSlice';
import CircularProgress from '@mui/material/CircularProgress';
import TasksTable from '../../../components/Dashboard/ManageTasks/TasksTable';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { getModifyValue, setModify } from '../../../app/slices/modifySlice';
import * as TaskServices from "../../../services/task-services.proxy";
import * as UserServices from "../../../services/user-services.proxy";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { getCallStatus, getErrorMsg, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import Message from '../../../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import TaskCard from '../../../sharedComponents/ui/TaskCard';
import Sun from '@mui/icons-material/WbSunnyOutlined';
import Fire from '@mui/icons-material/LocalFireDepartmentOutlined';
import Calendar from '@mui/icons-material/EventRepeatOutlined';
import Bookmark from '@mui/icons-material/BookmarkAddedOutlined';
import CreateTask from '../../../components/Dashboard/ManageTasks/CreateTask';

const getTaskList = TaskServices.getTaskList;
const getUserProfile = UserServices.getUserProfile;
const addTask = TaskServices.addTask;
const getTaskInputData = TaskServices.getTaskInputData;

const ManageTasks = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const token = useAppSelector((state) => state.userData.token);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const intl = useIntl();

  //select states 
  const modify = useAppSelector(getModifyValue);
  const dialogLoading = useAppSelector(getDialogLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const loading = useAppSelector(getLoadingState);
  const callStatus = useAppSelector(getCallStatus);
  const [initiallistLoading, setInitialListLoading] = useState(false);
  const [inputDataLoading, setInputDataLoading] = useState(false);

  //Data to fill 
  const [userData, setUserData] = useState<any>();
  const [tasksTable, setTasksTable] = useState([]);
  const [dataLength, setDataLength] = useState<any>();
  const [assigneesList, setAssigneesList] = useState<any>();
  const [clientsList, setClientsList] = useState<any>();
  const [typesList, setTypesList] = useState<any>();
  const [statusesList, setStatusesList] = useState<any>();

  //Add Task
  const [openAddTaskDialog, setOpenAddTaskDialog] = useState(false);

  //table filter states
  const [search, setSearch] = useState<string>();
  const [pageNb, setPageNb] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedStatus, setSelectedStatus]= useState<number[]>([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [last30days, setLast30days] = useState(false);

//total
const [newTasks, setNewTasks] = useState(0);
const [openTasks, setOpenTasks] = useState(0);
const [overdueTasks, setOverdueTasks] = useState(0);
const [doneTasks, setDoneTasks] = useState(0);


  const getUserProfileCall = () => {
    dispatch(setDialogLoading(true));
    getUserProfile(token).then((x) => {
      setUserData(x);
      dispatch(setDialogLoading(false));
    })
  }
  const listData =
  {
    searchTerm: search ? search : null,
    pageNumber: pageNb,
    pageSize: pageSize,
    statusId: selectedStatus.length > 0 ? selectedStatus : null,
    last30days: last30days,
   
  }
  const getTaskListCall = () => {
    dispatch(setDialogLoading(true)) 
    setInitialListLoading(true);
    getTaskList(listData).then((x) => {
      setTasksTable(x.tasks);
      setDataLength(x.total);
      setNewTasks(x.totalTasks.new);
      setOpenTasks(x.totalTasks.open);
      setOverdueTasks(x.totalTasks.overdue);
      setDoneTasks(x.totalTasks.done);
      dispatch(setDialogLoading(false));
      setInitialListLoading(false);
    });
  }

  const addTaskCall = (taskObject : {}) => {
    dispatch(setLoading(true));
    addTask(taskObject).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);

      }
      else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          setOpenAddTaskDialog(false);
          dispatch(setModify(!modify));
        }, 1000);
      }
      dispatch(setLoading(false));
    })

  }

  const getTaskInputDataCall = () => {
    setInputDataLoading(true);
    getTaskInputData().then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);

      }
      else {
        setAssigneesList(x.users);
        setClientsList(x.clients);
        setStatusesList(x.statuses);
        setTypesList(x.types);

        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 1000);
      }
      setInputDataLoading(false);
    })
  }

  useEffect(() => {
    getTaskListCall();
  }, [modify, search, selectedStatus, last30days]);

  useEffect(() => {
    getUserProfileCall();
    getTaskInputDataCall();
  }, []);

    useEffect(() => {
      
      let filtered=tasksTable
    if (selectedStatus.length > 0) {
       filtered = filtered.filter((task) => selectedStatus.includes(task));
          }

    if (last30days) {
      filtered = filtered.filter((task) => {
          const taskCreationDate = new Date(task); 
          return taskCreationDate.getTime() >= Date.now() - 30 * 24 * 60 * 60 * 1000; 
      });
  }
  setFilteredTasks(filtered);

  }, [selectedStatus,last30days, tasksTable]);

  const handleCardClick = (status: number) => {
    setSelectedStatus((prevSelected) => {
      if (prevSelected.includes(status)) {
        return prevSelected.filter((s) => s !== status);
      } else {
        return [...prevSelected, status];
      }
    });
    
  };
  const handleLast30DaysToggle = () => {
    setLast30days((prev) => !prev); 
};
  
  return (
    <>
      <Grid container direction='column' rowGap={1} width='100%'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={dialogLoading || initiallistLoading || inputDataLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid container direction="column" spacing={2} sx={{ width: '100%', p: 2 }}>
          <Grid item>
            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
              {!initiallistLoading && userData?.username && (
                `Hello ${userData.username}!`
              )}
            </Typography>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <TaskCard title={intl.formatMessage({ id: 'task.open-tasks' })} count={openTasks} icon={Sun} gradientColors={['#FBE6B1', '#F9F1DA']} backGroundColor={['#FBE6B1','#F9F1DA']} status={2} onCardClick={handleCardClick}  isSelected={selectedStatus.includes(2)}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TaskCard title={intl.formatMessage({ id: 'task.overdue-tasks' })} count={overdueTasks} icon={Fire} gradientColors={['#F7E6D9', '#F7E6D9']} backGroundColor={['#F17D72','#F9C69F']} status={3} onCardClick={handleCardClick}  isSelected={selectedStatus.includes(3)}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TaskCard title={intl.formatMessage({ id: 'task.new-tasks' })} count={newTasks} icon={Calendar} gradientColors={['#C8EBFF', '#69BAE8']} backGroundColor={['#C8EBFF','#69BAE8']} status={1} onCardClick={handleCardClick}  isSelected={selectedStatus.includes(1)}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TaskCard title={intl.formatMessage({ id: 'task.done-tasks' })} count={doneTasks} icon={Bookmark} gradientColors={['#CCF0EB', '#00B69B']} backGroundColor={['#CCF0EB','#99C1BB']} status={5} onCardClick={handleCardClick}  isSelected={selectedStatus.includes(5)}/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className='desktop-header-5'><Message id="task.my-tasks" className='desktop-header-5 font-weight-400 Field-input' /></Grid>
        <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} >
          <HomeOutlinedIcon style={{ color: '#93919A' }} /> <Message id="user.profile.home" className="Field-input desktop-paragraph font-weight-400" /> / <Message id="task.tasks" className="Field-input desktop-paragraph font-weight-400" />
          <Grid item container marginTop={2} alignItems='center'>

            {errorMsg ?
              <Grid item className='errorText' marginLeft={2}>{errorMsg}</Grid> :
              <></>
            }
          </Grid>
          <Grid item container bgcolor='#FEFEFF' borderRadius='8px' boxShadow='0px 2px 4px 0px #0000001F' padding={1}>
            <Grid item container direction='row' columnGap={2} padding={1}>
              <Grid item xs={5}>
                <TextField
                  value={search}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                    style: {
                      borderRadius: '35px',
                      fontFamily: 'DM Sans',
                      height: '48px',
                      backgroundColor: '#F7F9FF',
                      border: '1px solid #E6E6E8'
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-input::placeholder': {
                      fontWeight: 'bold',
                    },
                  }}
                  placeholder={intl.formatMessage({ id: 'text.search' })}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Grid>
              <Grid item style={{ border: '1px solid #E6E6E8', borderRadius: '8px', display: 'flex', alignItems: 'center', padding: 10, cursor: 'pointer' }}
                onClick={() => {
                  setSearch('');
                  setLast30days(false);
                  setSelectedStatus([]);
                  dispatch(setModify(!modify))
                }
                }><RestartAltIcon htmlColor='#C3C2C7' />
              </Grid>
              <Grid item width='140px'>
                <button className='greyButton'
                style={{backgroundColor:last30days?"#C8EBFF":""}}
                  onClick={handleLast30DaysToggle}
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center' style={{ color: '#93919A' }}>
                    <CalendarTodayOutlinedIcon fontSize="small" color="disabled" />
                    <Message id="task.last-30-days" className='Field-input font-weight-700 labelslink neutral-3' />
                  </Grid>

                </button>
              </Grid>
              {/* <Grid item width='100px'>
                <button className='greyButton'
                //   onClick={() => {
                //    
                //   }
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center' style={{ color: '#93919A' }}>
                    <FilterAltOutlinedIcon fontSize="small" />
                    <Message id="user.status" className='Field-input font-weight-700 labelslink neutral-3' />
                  </Grid>

                </button>
              </Grid>
              <Grid item width='120px'>
                <button className='greyButton'
                //   onClick={() => {
                //    
                //   }
                //   }
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center' style={{ color: '#93919A' }}>
                    <ViewWeekOutlinedIcon fontSize="small" />
                    <Message id="task.columns" className='Field-input font-weight-700 labelslink neutral-3' />
                  </Grid>

                </button>
              </Grid> */}
              <Grid item width='137px'>
                <button className='blueButton'
                  onClick={() => {
                    setOpenAddTaskDialog(true)
                  }
                  }
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center'>
                    <GroupAddOutlinedIcon />
                    <Message id="task.create-task" className='Field-input font-weight-700 labelslink offwhite-50' />
                  </Grid>

                </button>
              </Grid>
            </Grid>
            <TasksTable
              rows={tasksTable}
              dataLength={dataLength}
              setDataLength={setDataLength}
              modify={modify}
              setModify={setModify}
              statusesList={statusesList}
              typesList={typesList}
              assigneesList={assigneesList}
              clientsList={clientsList}
            />
          </Grid>
        </Grid>

        <CreateTask
          loading={loading}
          open={openAddTaskDialog}
          setOpen={setOpenAddTaskDialog}
          callStatus={callStatus}
          errorMsg={errorMsg}
          confirmAction={addTaskCall}
          assigneesList={assigneesList}
          clientsList={clientsList}
          statusesList={statusesList}
          typesList={typesList}
        />

      </Grid>

    </>
  );
}

export default ManageTasks;