import React, { useState, useEffect } from 'react'
import { Box, Grid, MenuItem, TextField } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Message from '../../../sharedComponents/ui/Message';
import classes from "../../../assets/styles/Task.module.css";
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import historyIcon from "../../../assets/images/icons/history.png";
import Messages from './Messages';
import History from './History';
import * as TaskServices from '../../../services/task-services.proxy';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getCallStatus, getErrorMsg, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import { getLoadingState, setDialogLoading, setLoading } from '../../../app/slices/loadingSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import moment from 'moment';

import Attachments from './Attachments';

const getTask = TaskServices.getTaskById;
const updateTask = TaskServices.updateTask;

export interface TaskProps {
  taskDetails: any;
  typesList: any;
  assigneesList: any;
  clientsList: any;
  statusesList: any;
  historyCategoriesList: any;
  updateTaskCall: any;
  addCommentCall: any;
  commentLoading: any;
  commentTxt: any;
  setCommentTxt: any;
}

const TaskDetailsContainer = (props: TaskProps) => {



  const { taskDetails, typesList, assigneesList, clientsList, statusesList, historyCategoriesList, updateTaskCall, addCommentCall, commentLoading, commentTxt, setCommentTxt } = props

  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState('Messages');

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));


  const navigate = useNavigate();
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg)
  const callStatus = useAppSelector(getCallStatus);

  //Files
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState<File[]>([]);
  const [fileId, setFileId] = useState(null);
  const [fileIds, setFileIds] = useState([]);


  // const files = [
  //   {
  //     id: 1,
  //     name: "File Name 1",
  //     size: '14KB',
  //     date: "5/9/2024"
  //   },
  //   {
  //     id: 2,
  //     name: "File Name 2",
  //     size: '14KB',
  //     date: "5/9/2024"
  //   },

  // ]

  useEffect(() => {
    if (file) {
      let tempFile = null;
      tempFile = file;
      files.push(tempFile);
    }
  }, [file]);

  // useEffect(() => {
  //   if (fileId) {
  //     let tempFileId = null;
  //     tempFileId = fileId;
  //     let fileData = { FileId: tempFileId };
  //     fileIds.push(fileData);
  //     // if (mrId) MRUpperDataUpdate();
  //   }
  // }, [fileId]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles) {
      // Handle the file upload logic here
      const filesArray = Array.from(uploadedFiles);
      setFiles(filesArray);
    }
  };

  return (
    <Grid item container direction='row' justifyContent='space-between'>
      <Grid item container direction='column' xs={7.5} rowGap={2}>
        <Grid item className='black desktop-header-5'>
          {/* {taskDetails?.title} */}
          <TextField
            fullWidth
            value={taskDetails?.title}
            onChange={(e) => {
              taskDetails.title = e.target.value
              forceUpdate();
            }}
            sx={{
              '& label.Mui-focused': {
                color: '#C3C2C7',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#B2BAC2',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#C3C2C7',
                },
                '&:hover fieldset': {
                  borderColor: '#B2BAC2',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#6F7E8C',
                },
              },
            }}
            InputProps={{ className: 'fieldDesign' }}
          />
        </Grid>
        <Grid item columnGap={1} container direction='row' className='neutral-3 bold' alignItems='center'>
          <HomeOutlinedIcon style={{ color: '#93919A' }} fontSize='small' /> <Message id="user.profile.home" className="Field-input desktop-paragraph neutral-3 bold" /> / <Message id="task.tasks" className="Field-input desktop-paragraph neutral-3 bold" /> / <Message id="task.task-id" className="Field-input desktop-paragraph neutral-3 bold" />
        </Grid>
        {/* <Grid item container rowGap={0.5} width='90%'>
          <span className='black labels-extra-bold'>Description</span>
          {taskDetails.title}
        </Grid> */}
        <Grid item container direction='column'>
          <Grid item className='black font-14 labels-extra-bold' >Description</Grid>
          <Grid item >
            {/* {taskDetails?.title} */}
            <TextField
              fullWidth
              value={taskDetails?.description}
              onChange={(e) => {
                taskDetails.description = e.target.value
                forceUpdate();
              }}
              sx={{
                '& label.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#C3C2C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
              InputProps={{ className: 'fieldDesign' }}
            />
          </Grid>
        </Grid>

        <Grid item container rowGap={2}>
          <Grid item container direction='row' columnGap={4}>
            <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.type" className="Field-input font-weight-700 font-14" /></Grid>
            <Grid item>
              {/* {taskDetails?.type} */}
              {typesList?.find((i: { id: any; }) => i.id === taskDetails?.type)?.name}
            </Grid>

          </Grid>
          <Grid item container direction='row' columnGap={4}>
            <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.client" className="Field-input font-weight-700 font-14" /></Grid>
            <Grid item className='labels'> {clientsList?.find((i: { id: any; }) => i.id === taskDetails?.clientId)?.name}
            </Grid>
          </Grid>
          <Grid item container direction='row' columnGap={4}>
            <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.module" className="Field-input font-weight-700 font-14" /></Grid>
            <Grid item className='labels'>N/A</Grid>
          </Grid>
        </Grid>
        <Grid item container className='whiteContainer'>
        <Attachments handleFileChange={handleFileChange} files={files}/>

        </Grid>
        <Grid item container className={classes['CommentContainer']}>
          <Grid item container direction='row'>
            <Grid item container width='180px' columnGap={1} alignItems='center' padding='15px'
              //  className='black font-14 labels-extra-bold' 
              className={classes[activeTab === 'Messages' ? 'messagesTabActive' : '']}
              style={{ cursor: activeTab === 'Messages' ? 'unset' : 'pointer' }}
              onClick={() => {
                setActiveTab('Messages')
              }}
            > <ChatOutlinedIcon htmlColor='#93919a' /> <Message id="task.messages" className="Field-input font-14" />    <span className='nbCircle'>{taskDetails?.comments?.length}</span> </Grid>
            <Grid item container width='180px'
              style={{ cursor: activeTab === 'History' ? 'unset' : 'pointer' }}
              columnGap={1} alignItems='center' padding='15px' className={classes[activeTab === 'History' ? 'historyTabActive' : '']}
              onClick={() => {
                setActiveTab('History')
              }}
            >
              <img src={historyIcon} alt='icon' /><Message id="task.history" className="Field-input font-14" /> </Grid>
          </Grid>
          <Grid item container padding={3}>
            {activeTab === 'Messages' ?
              <Messages taskDetails={taskDetails} addCommentCall={addCommentCall} commentLoading={commentLoading}
                commentTxt={commentTxt}
                setCommentTxt={setCommentTxt} />

              :
              <History taskDetails={taskDetails} historyCategoriesList={historyCategoriesList} />}
          </Grid>

        </Grid>
        <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
          <Grid item xs={3}
            onClick={() => {
              if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
              else navigate('/tasks')
            }}>
            <button className='greyButton'><Message id="button.cancel" className='labels-extra-bold Field-input labelsLinks' /></button>
          </Grid>
          <Grid item xs={3}>
            <button
              className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
              disabled={loading || !taskDetails?.title}
              onClick={() => { updateTaskCall() }}>
              {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                  : callStatus === 'Fail' ? "Failed"
                    : <Message id="button.apply" className='labels-extra-bold Field-input labelsLinks' />}
            </button>
            <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={4} className='whiteContainer' height={400} rowGap={1} justifyContent='center' alignItems='center'>
        <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="user.status" className='Field-input font-weight-700 font-14' /></span>
        </Grid>

        <Grid item xs={6}>
          <TextField
            value={taskDetails?.status}
            select
            onChange={(e) => {
              taskDetails.status = e.target.value
              forceUpdate();
            }}
            sx={{
              '& label.Mui-focused': {
                color: '#00B69B',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#B2BAC2',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#00B69B',
                },
                '&:hover fieldset': {
                  borderColor: '#00B69B',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#00B69B',
                },
              },
            }}
            InputProps={{ className: 'statusFieldDesign', style: { width: '150px' } }}
          >
            {statusesList?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>

        </Grid>
        <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="task.assignee" className='Field-input font-weight-700 font-14' /></span>
        </Grid>

        <Grid item xs={6}>
          <Box display="flex" alignItems="center">
            <AccountCircleIcon style={{ width: '40px', height: '40px' }} />
            <Grid item width='100%' xs={9}>
              <TextField
                fullWidth
                select
                value={taskDetails?.assigneeId}
                onChange={(e) => {
                  taskDetails.assigneeId = e.target.value
                  forceUpdate();
                }}
                sx={{
                  '& label.Mui-focused': {
                    color: 'white',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: 'white',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white  ',
                    },
                  },
                }}
                InputProps={{ className: 'fieldDesign' }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 150, // Set the max height of the dropdown
                      },
                    },
                  },
                }}
              >
                {assigneesList?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.email}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="task.due-date" className='Field-input font-weight-700 font-14' /> </span>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            value={taskDetails?.dueDate}
            onChange={(e) => {
              taskDetails.dueDate = e.target.value
              forceUpdate();
            }}
            InputLabelProps={{
              shrink: false,
            }}
            sx={{
              '& label.Mui-focused': {
                color: 'white',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: 'white',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white  ',
                },
              },
            }} size="small"
            label={""}
            type={"date"}
            variant="outlined"
          />
        </Grid>
        {/* <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="task.relevant-period" className='Field-input font-weight-700 font-14' /></span>
        </Grid>
        <Grid item xs={6}>
          N/A
        </Grid>
        <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="task.recurring-task" className='Field-input font-weight-700 font-14' /></span>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            disabled
            control={<IOSSwitch sx={{ m: 1 }} />}
            label=""
          />
        </Grid>
        <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="task.recurring-period" className='Field-input font-weight-700 font-14' /></span>
        </Grid>
        <Grid item xs={6}>
          N/A
        </Grid>
        <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="task.priority" className='Field-input font-weight-700 font-14' /></span>
        </Grid>
        <Grid item xs={6}>
          N/A
        </Grid>
        <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="task.note" className='Field-input font-weight-700 font-14' /></span>
        </Grid>
        <Grid item xs={6}>
          N/A
        </Grid> */}

      </Grid>
    </Grid>
  )
}

export default TaskDetailsContainer