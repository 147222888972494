import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Grid, MenuItem, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import classes from "../../../../assets/styles/User.module.css";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import Message from '../../../sharedComponents/ui/Message';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export interface DialogProps {
  open: boolean;
  setOpen: CallableFunction;
  loading: any,
  callStatus: any,
  errorMsg: any,
  confirmAction: any,
  assigneesList: any;
  clientsList: any;
  statusesList: any;
  typesList: any;
}

const CreateTask = (props: DialogProps) => {

  const { open, setOpen, loading, callStatus, errorMsg, confirmAction, assigneesList, clientsList, statusesList, typesList } = props;



  const [taskObject, setTaskObject] = useState({
    title: "",
    dueDate: "",
    description: "",
    status: 1,
    type: 1,
    clientId: localStorage.getItem('userType') === '1' ?"":null,
    assigneeId: "",
  })


  const handleOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      handleOpen();
    }
    else {
      setOpen(false);
    }
  }, [open]);

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ sx: { borderRadius: "8px", width: "70%" } }}
    >
      <Grid item container padding={2.5} direction='column' rowGap={3} >
        <Grid item container className='neutral-4' fontWeight={400} alignItems='center' justifyContent='space-between'>
          <Message id="task.create-new-task" className='Field-input font-weight-400 font-14' />
          <span style={{ cursor: 'pointer' }} onClick={() => handleClose()}><CloseIcon /></span>
          <Grid item className='greyLine' width='100%' xs={12}></Grid>

        </Grid>
        <Grid item container direction='column'>
          <Grid item className='neutral-9 font-weight-400'><Message id="task.info" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
          <Grid item>
            <TextField
              fullWidth
              value={taskObject.title}
              onChange={(e) => {
                taskObject.title = e.target.value
                forceUpdate();
              }}
              sx={{
                '& label.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#C3C2C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
              InputProps={{ className: 'fieldDesign' }}
            />
          </Grid>
        </Grid>

        <Grid item container direction='column'>
          <Grid item className='neutral-9 font-weight-400'><Message id="task.description" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
          <Grid item>
            <TextField
              fullWidth
              value={taskObject.description}
              onChange={(e) => {
                taskObject.description = e.target.value
                forceUpdate();
              }}
              sx={{
                '& label.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#C3C2C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
              InputProps={{ className: 'fieldDesign' }}
            />
          </Grid>
        </Grid>

        <Grid item container direction='row' columnSpacing={1.5}>
          <Grid item container direction='column' xs={4}>
            <Grid item><Message id="task.task-type" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
            <Grid item>
              <TextField
                fullWidth
                select
                value={taskObject.type}
                onChange={(e) => {
                  taskObject.type = parseInt(e.target.value);
                  forceUpdate();
                }}
                sx={{
                  '& label.Mui-focused': {
                    color: '#C3C2C7',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#B2BAC2',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#C3C2C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#B2BAC2',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6F7E8C',
                    },
                  },
                }}
                InputProps={{ className: 'fieldDesign' }}
              >
                {typesList?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item container direction='column' xs={4}>
            <Grid item><Message id="user.status" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
            <Grid item>
              <TextField
                fullWidth
                value={taskObject.status}
                select
                onChange={(e) => {
                  taskObject.status = parseInt(e.target.value);
                  forceUpdate();
                }}
                sx={{
                  '& label.Mui-focused': {
                    color: '#C3C2C7',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#B2BAC2',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#C3C2C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#B2BAC2',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6F7E8C',
                    },
                  },
                }}
                InputProps={{ className: 'fieldDesign' }}
              >
                {statusesList?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid item container direction='column' xs={4}>
            <Grid item><Message id="task.due-date" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
            <Grid item>
              <TextField
                fullWidth
                value={taskObject.dueDate}
                onChange={(e) => {
                  taskObject.dueDate = e.target.value
                  forceUpdate();
                }}
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{ className: 'fieldDesign' }}
                size="small"
                label={""}
                type={"date"}
                variant="outlined"
              />
            </Grid>
          </Grid>

        </Grid>

        <Grid item className='neutral-9 font-weight-400 '>
          {localStorage.getItem('userType') === '1' ?
            <Message id="task.client-assignee-info" className='Field-input font-weight-400 mobile-paragraph' />
            :
            "Assignee Info"
          }

        </Grid>
        <Grid item container direction='row' columnSpacing={1.5}>
          {localStorage.getItem('userType') === '1' ?
            <Grid item container direction='column' xs={6}>
              <Grid item><Message id="task.client" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
              <Grid item width='100%'>
                <TextField
                  fullWidth
                  select
                  value={taskObject.clientId}
                  onChange={(e) => {
                    taskObject.clientId = e.target.value
                    forceUpdate();
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: '#C3C2C7',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#B2BAC2',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#C3C2C7',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B2BAC2',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  InputProps={{ className: 'fieldDesign' }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 150, // Set the max height of the dropdown
                          width: 'fit-content', // Adjust width if needed
                        },
                      },
                    },
                  }}
                >
                  {clientsList?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            :
            <></>
          }
          <Grid item container direction='column' xs={localStorage.getItem('userType') === '1' ?6:12}>
            <Grid item><Message id="task.assignee" className='Field-input font-weight-400 mobile-paragraph' /></Grid>
            <Grid item width='100%'>
              <TextField
                fullWidth
                select
                value={taskObject.assigneeId}
                onChange={(e) => {
                  taskObject.assigneeId = e.target.value
                  forceUpdate();
                }}
                sx={{
                  '& label.Mui-focused': {
                    color: '#C3C2C7',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#B2BAC2',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#C3C2C7',
                    },
                    '&:hover fieldset': {
                      borderColor: '#B2BAC2',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6F7E8C',
                    },
                  },
                }}
                InputProps={{ className: 'fieldDesign' }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 150, // Set the max height of the dropdown
                      },
                    },
                  },
                }}
              >
                {assigneesList?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.email}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

        </Grid>

        <Grid item>
          <button
            className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
            disabled={loading || !taskObject.title || !taskObject.type || !taskObject.status || !taskObject.dueDate || (localStorage.getItem('userType') === '1' && !taskObject.clientId) || !taskObject.assigneeId}
            onClick={() => confirmAction(taskObject)}>
            {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
              : callStatus === 'Pass' ? <CheckCircleOutlinedIcon />
                : callStatus === 'Fail' ? "Failed"
                  :
                  <>
                    <LocalHospitalOutlinedIcon fontSize='small' /><Message id="task.create-task" className='Field-input font-weight-700 font-14' /></>
            }</button>
        </Grid>
        {errorMsg ?
          <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
          :
          <></>}
      </Grid>
    </Dialog>
  )
}

export default CreateTask