import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Message from './Message';

interface TaskCardProps {
  title: string;
  count: number;
  icon: React.ElementType;
  gradientColors: [string, string];
  backGroundColor: [string, string];
  status: number; 
  onCardClick: (status: number) => void; 
  isSelected: boolean;
}

const TaskCard: React.FC<TaskCardProps> = ({ title, count, icon: Icon, gradientColors, backGroundColor, status, onCardClick, isSelected }) => {

  const [isClicked, setIsClicked]= useState(false);
  

  return (
    <Box
    onClick={() => onCardClick(status)}
      sx={{
        minWidth: 'auto',
        maxWidth:'400px',
        height: '184px',
        borderRadius: '16px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: isSelected ? `linear-gradient(to bottom, ${backGroundColor[0]}, ${backGroundColor[1]})` :'transparent',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '16px',
          padding: '2px',
          background: `linear-gradient(to bottom right, ${gradientColors[0]}, ${gradientColors[1]})`,
          WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          WebkitMaskComposite: 'xor',
          maskComposite: 'exclude',
        },
        '&:hover':{
          background: `linear-gradient(to bottom, ${backGroundColor[0]}, ${backGroundColor[1]})`,
        },
        cursor:'pointer'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', fontSize:'26px' }}>
          {title}
        </Typography>
        <Icon fontSize='large'/> 
      </Box>
      <Typography variant="h3" component="div" sx={{ fontWeight: 'bold', my: 2, fontSize:'64px' }}>
        {count} 
      </Typography>
      <Typography 
        variant="body2" 
        sx={{ 
          color: 'text.secondary',
          position: 'absolute',
          bottom: '43px', 
          right: '16px',
          '&:hover': {
          textDecoration: 'underline', 
          cursor:'pointer',
        },
        }}
      >
       <Message id="task.view-tasks" className='Field-input font-weight-400 labelsLinks neutral-5 underline-text' />
      </Typography>
    </Box>
  );
};

export default TaskCard;