import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material';
import Message from '../../../sharedComponents/ui/Message';
import classes from "../../../assets/styles/Task.module.css";
import downloadIcon from "../../../assets/images/icons/download.png";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export interface propsInterface {
    handleFileChange: any;
    files: any
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: '0px 2px 4px 0px #0000001F',
        backgroundColor: '#FEFEFF',
        padding: '10px',
        borderRadius: '10px'
    },
}));

const Attachments = (props: propsInterface) => {

    const { handleFileChange, files } = props;
    const today: String = new Date().toLocaleDateString();

    return (

        <Grid container rowGap={1}>
            <Grid item container direction='row' justifyContent='space-between'>
                <Grid item container xs={6} className='black labels-extra-bold font-14' alignItems='center' columnGap={1}><AttachFileIcon htmlColor='#93919A' /><Message id="task.attachments" className="Field-input font-weight-700 font-14" />  <span className='nbCircle'>nb</span> </Grid>
                <Grid item width='155px' container justifyContent='flex-end' >
                    <input
                        type="file"
                        onChange={handleFileChange}
                        multiple
                        style={{ display: 'none' }} // Hide the default input
                        id="file-upload" // Use an ID for the label
                    />
                    <label htmlFor="file-upload" className={classes['addFileButton']}>
                        <Message id="task.add-attachments" className="Field-input font-weight-700 font-14" />
                    </label>
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item container direction='row' className='labels neutral-3' columnGap={1}>
                    <Grid item xs={5}>Name</Grid>
                    <Grid item xs={1.5}>Size</Grid>
                    <Grid item xs={3.5}>Date added</Grid>
                </Grid>
                <Grid item className='grey-line-full-width'></Grid>
            </Grid>
            <Grid item container rowGap={0.5} marginTop={1} style={{ overflowY: 'auto', maxHeight: '130px' }} >
                {files.length > 0 && files?.map((i: any) => (
                    <Grid item container direction="row" key={i.id} columnGap={1} className='black bold labels' alignItems='center'>
                        <Grid item container alignItems='center' xs={5} columnGap={0.4}><InsertDriveFileIcon fontSize='small' htmlColor='#93919A'/>{i.name}</Grid>
                        <Grid item xs={1.5}>{i.size} KB</Grid>
                        <Grid item xs={3.5}> {today}</Grid>
                        <Grid item container direction='row' xs={1.5} alignItems='center' justifyContent='flex-end' columnGap={1}>
                            <Grid item> <img alt='icon' src={downloadIcon} width={17} height={17} style={{ cursor: 'pointer' }} /> </Grid>
                            <Grid item>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Grid item container direction='column' rowGap={1} justifyContent='center' alignItems='center' >
                                                <Grid item container direction='row' alignItems='center' columnGap={0.5}>
                                                    <Grid item><DeleteOutlineIcon htmlColor='#EF4737' style={{ width: "19px", height: "19px", marginTop: '3px' }} /></Grid>
                                                    <Grid item className='red labels bold'>Delete</Grid>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                >
                                    <MoreVertIcon htmlColor='#93919A' style={{ cursor: 'pointer' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>

                    </Grid>
                ))}
            </Grid>
            <Grid item container justifyContent='flex-end' marginTop={1}>
                <button className='blueButton' style={{ width: '200px' }} disabled>
                    Update Documents
                </button>
            </Grid>
        </Grid>
    )

}
export default Attachments