import React, { useState, useEffect, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, Grid, TextField } from '@mui/material';
import { getDialogLoadingState, setDialogLoading } from '../../app/slices/loadingSlice';
import CircularProgress from '@mui/material/CircularProgress';
import TeamMembersTable from '../../components/Dashboard/ManageUsers/TeamMembersTable';
import ClientsTable from '../../components/Dashboard/ManageUsers/ClientsTable';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { getModifyValue, setModify } from '../../app/slices/modifySlice';
import * as UserServices from "../../services/user-services.proxy";
import * as ClientServices from "../../services/client-services.proxy";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { getErrorMsg, setErrorMsg } from '../../app/slices/apiCallSlice';
import Message from '../../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
import { selectRoles } from '../../app/slices/userDataSlice';
import * as userRolesConst from "../../user-roles-consts";

const getTMUsersList = UserServices.getTMUsersList;
const getClientsList = ClientServices.getInvitedClients;

const ManageUsers = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //query params
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const queryActiveTab = query.get('type');

  const [activeTab, setActiveTab] = useState<string>(queryActiveTab && parseInt(queryActiveTab) === 2 ? 'Clients' : 'Team');
  const [search, setSearch] = useState<string>();

const intl= useIntl();

  //select states 
  const modify = useAppSelector(getModifyValue);
  const dialogLoading = useAppSelector(getDialogLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);

  //data to fill
  const [teamMembersTable, setTeamMembersTable] = useState([]);
  const [clientsTable, setClientsTable] = useState<any[]>([]);
  const [dataLength, setDataLength] = useState<any>();
  const [roles, setRoles] = useState([]);

  //filters 
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [statusId, setStatusId] = useState<any>();

  const [initiallistLoading, setInitialListLoading] = useState(false);

  const [paginationModel, setPaginationModel] = useState({ page: 1, pageSize: 5 });
  const [pageNb, setPageNb] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const userRoles = useAppSelector(selectRoles);


  const listData =
  {
    searchTerm: search ? search : null,
    pageNumber: pageNb,
    pageSize: pageSize,
    roles: selectedRoles,
    statusId: activeTab === 'Clients' ? statusId : 0,
  }

  const getTeamMembersListCall = () => {
    dispatch(setDialogLoading(true));
    setInitialListLoading(true);
    getTMUsersList(listData).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);
      } else {
        setTeamMembersTable(x.users);
        setDataLength(x.total);
        setRoles(x.roles);
      }
      dispatch(setDialogLoading(false));
      setInitialListLoading(false);
    })
  }

  const getClientsListCall = () => {
    dispatch(setDialogLoading(true));
    getClientsList(listData).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);
      } else {
        setClientsTable(x.clients);
        setDataLength(x.total);
        setRoles(x.roles);
      }
      dispatch(setDialogLoading(false));
    })
  };

  useEffect(() => {
    if (activeTab === 'Team') getTeamMembersListCall();
    if (activeTab === 'Clients') getClientsListCall();
  }, [modify, activeTab, search, selectedRoles, statusId, pageSize, pageNb])

  return (
    <>
      {localStorage.getItem('userType') === '1' ?
        <Grid container direction='column' rowGap={1} width='100%'>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={dialogLoading || initiallistLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid item className='desktop-header-5'><Message id="user.profile.manage-user" className="Field-input desktop-header-5 font-weight-400" /></Grid>
          <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} >
            <HomeOutlinedIcon style={{ color: '#93919A' }} /> <Message id="user.profile.home" className="Field-input desktop-paragraph font-weight-400" /> / <Message id="user.profile.user-management" className="Field-input desktop-paragraph font-weight-400" /> / <Message id="user.profile.manage-users" className="Field-input desktop-paragraph font-weight-400" />
            <Grid item container marginTop={2} alignItems='center'>
              <Grid item className={activeTab === 'Team' ? 'activeTabs' : 'tabs'}
                onClick={() => setActiveTab('Team')}>
                <Message id="button.team" className="labelsLinks labels-extra-bold Field-input " />
              </Grid>
              <Grid item className={activeTab === 'Clients' ? 'activeTabs' : 'tabs'}
                onClick={() => setActiveTab('Clients')}>
                 <Message id="button.clients" className="labelsLinks labels-extra-bold Field-input " />
              </Grid>
              {errorMsg ?
                <Grid item className='errorText' marginLeft={2}>{errorMsg}</Grid> :
                <></>
              }
            </Grid>
            <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F'>
              <Grid item container direction='row' columnGap={2} padding={1}>
                <Grid item xs={5}>
                  <TextField
                    value={search}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                      style: {
                        borderRadius: '35px',
                        fontFamily: 'DM Sans',
                        height: '48px',
                        backgroundColor: '#F7F9FF',
                        border: '1px solid #E6E6E8'
                      },
                    }}
                    sx={{
                      '& .MuiInputBase-input::placeholder': {
                        fontWeight: 'bold',
                      },
                    }}
                    placeholder={intl.formatMessage({ id: 'text.search' })}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Grid>
                <Grid item style={{ border: '1px solid #E6E6E8', borderRadius: '8px', display: 'flex', alignItems: 'center', padding: 10, cursor: 'pointer' }}
                  onClick={() => {
                    setStatusId(null);
                    setSearch('');
                    setSelectedRoles([]);
                    dispatch(setModify(!modify))
                  }
                  }><RestartAltIcon htmlColor='#C3C2C7' />
                </Grid>
                <Grid item width='200px'>
                  <button className='blueButton'
                    onClick={() => {
                      if (activeTab === "Team") navigate('/add-users?type=1')
                      else navigate('/add-users?type=2')
                    }
                    }>
                    <Grid item container alignItems='center' columnGap={1} justifyContent='center'>
                      <GroupAddOutlinedIcon />
                      {activeTab === "Team" ? <Message id="button.add-team-member" className="labelsLinks labels-extra-bold Field-input " /> : <Message id="button.add-client" className="labelsLinks labels-extra-bold Field-input " />}
                    </Grid>

                  </button>
                </Grid>
              </Grid>
              {activeTab === 'Team' ?
                <TeamMembersTable
                  rows={teamMembersTable}
                  dataLength={dataLength}
                  setDataLength={setDataLength}
                  roles={roles}
                  selectedRoles={selectedRoles}
                  setSelectedRoles={setSelectedRoles}
                  modify={modify}
                  setModify={setModify}
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                  pageNb={pageNb}
                  setPageNb={setPageNb}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
                :
                <ClientsTable
                  rows={clientsTable}
                  dataLength={dataLength}
                  setDataLength={setDataLength}
                  roles={roles}
                  selectedRoles={selectedRoles}
                  setSelectedRoles={setSelectedRoles}
                  modify={modify}
                  setModify={setModify}
                  statusId={statusId}
                  setStatusId={setStatusId}
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                  pageNb={pageNb}
                  setPageNb={setPageNb}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />}
            </Grid>
          </Grid>
        </Grid>

        :

        
        <Grid container direction='column' rowGap={1} width='100%'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={dialogLoading || initiallistLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item className='desktop-header-5'><Message id="user.profile.manage-user" className="Field-input desktop-header-5 font-weight-400" /></Grid>
        <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} >
          <HomeOutlinedIcon style={{ color: '#93919A' }} /> <Message id="user.profile.home" className="Field-input desktop-paragraph font-weight-400" /> / <Message id="user.profile.user-management" className="Field-input desktop-paragraph font-weight-400" /> / <Message id="user.profile.manage-users" className="Field-input desktop-paragraph font-weight-400" />
          <Grid item container marginTop={2} alignItems='center'>
            <Grid item className='activeTabs'>
              <Message id="button.team" className="labelsLinks labels-extra-bold Field-input " />
            </Grid>
            {errorMsg ?
              <Grid item className='errorText' marginLeft={2}>{errorMsg}</Grid> :
              <></>
            }
          </Grid>
          <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F'>
            <Grid item container direction='row' columnGap={2} padding={1}>
              <Grid item xs={5}>
                <TextField
                  value={search}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                    style: {
                      borderRadius: '35px',
                      fontFamily: 'DM Sans',
                      height: '48px',
                      backgroundColor: '#F7F9FF',
                      border: '1px solid #E6E6E8'
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-input::placeholder': {
                      fontWeight: 'bold',
                    },
                  }}
                  placeholder={intl.formatMessage({ id: 'text.search' })}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Grid>
              <Grid item style={{ border: '1px solid #E6E6E8', borderRadius: '8px', display: 'flex', alignItems: 'center', padding: 10, cursor: 'pointer' }}
                onClick={() => {
                  setStatusId(null);
                  setSearch('');
                  setSelectedRoles([]);
                  dispatch(setModify(!modify))
                }
                }><RestartAltIcon htmlColor='#C3C2C7' />
              </Grid>
              <Grid item width='200px'>
                <button className='blueButton'
                  onClick={() => {
                     navigate('/add-users?type=1')
                  }
                  }>
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center'>
                    <GroupAddOutlinedIcon />
                     <Message id="button.add-team-member" className="labelsLinks labels-extra-bold Field-input " /> 
                  </Grid>

                </button>
              </Grid>
            </Grid>
            
              <TeamMembersTable
                rows={teamMembersTable}
                dataLength={dataLength}
                setDataLength={setDataLength}
                roles={roles}
                selectedRoles={selectedRoles}
                setSelectedRoles={setSelectedRoles}
                modify={modify}
                setModify={setModify}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                pageNb={pageNb}
                setPageNb={setPageNb}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
          </Grid>
        </Grid>
      </Grid>
      }
    </>
  )
}

export default ManageUsers