import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Grid, TextField } from '@mui/material';
import classes from "../../../assets/styles/Table.module.css";
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import selectedFilter from "../../../assets/images/icons/selectedFilter.png"

export interface TableProps {
  rows: any;
  dataLength: any;
  setDataLength: any;
  tenants: any;
  selectedTenants: any,
  setSelectedTenants: any,
  roles: any;
  selectedRoles: any,
  setSelectedRoles: any,

}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
    // maxWidth: 'none',  // Ensure the width is not constrained
    // whiteSpace: 'nowrap',  // Prevent line breaks for content
    // overflow: 'hidden',  // Hide overflow to manage content display
    // textOverflow: 'ellipsis'
  },
}));

const Table = (props: TableProps) => {

  const { rows, tenants, selectedTenants, setSelectedTenants, roles, selectedRoles, setSelectedRoles } = props

  const positions: string[] = [];
  const companies: string[] = [];
  const [filterIndex, setFilterIndex] = useState<number>();
  const [search, setSearch] = useState<string>();
  const [pageSize, setPageSize] = useState();
  const [pageNb, setPageNb] = useState();

const [filteredRoles, setFilteredRoles] = useState<any>(roles);
const [filteredTenants, setFilteredTenants] =useState<any>(tenants);

const handleSearch= (e:React.ChangeEvent<HTMLInputElement>)=>{
const searchValue = e.target.value.toLowerCase();
setSearch(searchValue);
const filtered = roles?.filter((role: any) => 
  role.name.toLowerCase().includes(searchValue)
);
setFilteredRoles(filtered);
}

const handleCompanySearch = (e:React.ChangeEvent<HTMLInputElement>)=>{
  const searchValue = e.target.value.toLowerCase();
  setSearch(searchValue);
  const filtered= tenants?.filter((tenant:any)=>
    tenant?.name?.toLowerCase().includes(searchValue)
  );
  setFilteredTenants(filtered);
}

useEffect(()=>{
  setFilteredRoles(roles);
},[roles])

useEffect(()=>{
setFilteredTenants(tenants);
},[tenants])
  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    {
      field: 'roles', headerName: 'User Role', width: 170,
      renderHeader(params: any) {
        return (
          <Grid item container className='table-header' alignItems='center' fontWeight='normal'>User Role
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Grid item container rowGap={1} maxHeight='250px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder='Search'
                        onChange={handleSearch}
                      /></Grid>
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={1.5} style={{ cursor: selectedRoles.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setSelectedRoles([])} ><RestartAltIcon fontSize='small' /> Remove filter</Grid>
                    {filteredRoles.map((val: any, index: number) => (
                      <Grid item container alignItems='center' key={val.id} columnGap={1} className={classes[filterIndex === index || selectedRoles.includes(val.id) ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                        onMouseOver={(e) => {
                          const target = e.target as HTMLElement;
                          if (!selectedRoles.includes(val.id)) {
                            target.style.cursor = 'pointer';
                            setFilterIndex(index);
                          }
                        }}
                        onMouseOut={() => {
                          setFilterIndex(-1);
                        }}
                        onClick={(e) => {
                          if (!selectedRoles.includes(val.id)) {
                            setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                          }
                        }}
                      >
                        <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid>
                        <Grid item>{val.name}</Grid>
                      </Grid>
                    ))}

                  </Grid>
                </React.Fragment>
              }
            >
              <ArrowDropDownIcon />
            </HtmlTooltip >
          </Grid >
        )
      },
    },
    { field: 'email', headerName: 'Email', width: 270 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
    // {
    //   field: 'position', headerName: 'Position', width: 150,
    //   renderHeader(params: any) {
    //     return (
    //       <Grid item container className='table-header' alignItems='center' fontWeight='normal'>Position
    //         <HtmlTooltip
    //           title={
    //             <React.Fragment>
    //               <Grid item container rowGap={1} justifyContent='center' alignItems='center' padding={1} maxHeight='250px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
    //                 <Grid item>
    //                   <TextField
    //                     value={search}
    //                     fullWidth
    //                     InputProps={{
    //                       startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
    //                       style: {
    //                         borderRadius: '35px',
    //                         fontFamily: 'DM Sans',
    //                         height: '37px',
    //                         backgroundColor: '#F7F9FF',
    //                         border: 0,
    //                         borderColor: '#E6E6E8',
    //                         fontSize: '12px'
    //                       },
    //                     }}
    //                     sx={{
    //                       '& .MuiInputBase-input::placeholder': {
    //                         fontWeight: 'bold',
    //                       },
    //                     }}
    //                     placeholder='Search'
    //                     onChange={(e) => setSearch(e.target.value)}
    //                   /></Grid>
    //                 {positions.map((val: string, index: number) => (
    //                   <Grid item container alignItems='center' columnGap={1} className={classes[filterIndex === index ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
    //                     onMouseOver={(e) => {
    //                       const target = e.target as HTMLElement;
    //                       target.style.cursor = 'pointer';
    //                       setFilterIndex(index);

    //                     }}
    //                     onMouseOut={() => {
    //                       setFilterIndex(-1);
    //                     }}
    //                   >
    //                     <Grid className='bordered-box' bgcolor={filterIndex === index ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index ? '#00B69B' : '#9E9E9E'} /></Grid>
    //                     <Grid item>{val}</Grid>
    //                   </Grid>
    //                 ))}
    //                 <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={1.5}
    //                 ><RestartAltIcon fontSize='small' /> Remove filter</Grid>
    //               </Grid>
    //             </React.Fragment>
    //           }
    //         >
    //           <ArrowDropDownIcon />
    //         </HtmlTooltip >
    //       </Grid >
    //     )
    //   },
    // },
    {
      field: 'companyName', headerName: 'Company Name', width: 230,
      renderHeader(params: any) {
        return (
          <Grid item container className='table-header' alignItems='center' fontWeight='normal'>Company
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Grid item container rowGap={1} width='auto' maxHeight='250px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder='Search'
                        onChange={handleCompanySearch}
                      /></Grid>
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={1.5} style={{ cursor: selectedTenants.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setSelectedTenants([])} ><RestartAltIcon fontSize='small' /> Remove filter</Grid>
                    {filteredTenants.map((val: any, index: number) => (
                      <Grid item container alignItems='center' key={val.id} columnGap={1} className={classes[filterIndex === index || selectedTenants.includes(val.id) ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                        onMouseOver={(e) => {
                          const target = e.target as HTMLElement;
                          if (!selectedTenants.includes(val.id)) {
                            target.style.cursor = 'pointer';
                            setFilterIndex(index);
                          }
                        }}
                        onMouseOut={() => {
                          setFilterIndex(-1);
                        }}
                        onClick={(e) => {
                          if (!selectedTenants.includes(val.id)) {
                            setSelectedTenants((prevTenants: any) => [...prevTenants, val.id]);
                          }
                        }}
                      >
                        <Grid className='bordered-box' bgcolor={filterIndex === index || selectedTenants.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedTenants.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid>
                        <Grid item>{val.name}</Grid>
                      </Grid>
                    ))}

                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <ArrowDropDownIcon /> {selectedTenants.length > 0 ? <img src={selectedFilter} alt='icon' /> : <></>}
              </Grid>
            </HtmlTooltip>

          </Grid >
        )
      },
    },
    { field: 'primaryContactCmpany', headerName: 'Primary Contact for Company', width: 300 },
    { field: 'country', headerName: 'Country', width: 200 },

    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 90,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
    // },
  ];

  return (
    <div style={{ height: '53vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25]}
        checkboxSelection
        disableColumnMenu
        sx={{
          border: 2,
          borderColor: '#FEFEFF',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
      />
    </div>
  )
}

export default Table