import React, { useEffect, useState } from 'react';
import classes from "../Admin/assets/styles/Navbar.module.css";
import { Grid } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useNavigate } from 'react-router-dom';
import * as userServices from "../services/user-services.proxy";
import AlertDialog from '../sharedComponents/AlertDialog';
import { setIsCMS, setIsLoggedIn } from '../app/slices/userDataSlice';
import { getDialogLoadingState, setDialogLoading } from '../app/slices/loadingSlice';
import Message from '../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
const getUserProfile = userServices.getUserProfile;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FEFEFF',
        color: '#605E6B',
        borderRadius: '20px',
        boxShadow: '0px 2px 4px 0px #0000001F'
    },
}));

export interface UpperNavProps {
    modifyProfile: boolean,
}

const UpperNavbar = (props: UpperNavProps) => {
    const { modifyProfile } = props
    const intl = useIntl();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const token = useAppSelector((state) => state.userData.token);
    const [userData, setUserData] = useState<any>();
    const [openAlert, setOpenAlert] = useState(false);

    const dialogLoading = useAppSelector(getDialogLoadingState)

    useEffect(() => {
        getUserProfileCall();
    }, [modifyProfile]);

    const getUserProfileCall = () => {
        dispatch(setDialogLoading(true));
        getUserProfile(token).then((x) => {
            setUserData(x);
            dispatch(setDialogLoading(false));

        })
    }

    const logoutMethod = () => {
        localStorage.clear();
        dispatch(setIsLoggedIn(false));
        dispatch(setIsCMS(false));
        localStorage.setItem('loginFormState', 'default')
        navigate('/');
    }

    return (
        <Grid item container direction='row' xs={12} alignItems='center' justifyContent='flex-end' padding={2}>
            <Grid item container xs={2} className='labels-extra-bold' alignItems='center' columnGap={1} justifyContent='flex-end'>
                <AccountCircleIcon style={{ width: '40px', height: "40px" }} />
                <Grid item><span className='labels-extra-bold' style={{ color: '#404040' }}>{userData?.username}</span><br /> <span className='labels' style={{ color: '#565656', fontWeight: '600' }}>{userData?.position}</span></Grid>
                <Grid item>
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Grid item container direction='column' className={classes['dropdownContainer']} rowGap={3}>
                                    <Grid item
                                        onClick={() => {
                                            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/profile')
                                            else navigate('/profile')
                                        }}
                                        className='labelsLinks'> <Message id="user.edit-profile" className='labelsLinks' /></Grid>
                                    {/* <Grid item className='labelsLinks'> Subscription</Grid>
                                    <Grid item className='labelsLinks'> Privacy setting</Grid> */}
                                    <Grid item className='labelsLinks'
                                        onClick={() => { setOpenAlert(true) }}> <Message id="button.logout" className='labelsLinks' /></Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    >
                        <ArrowDropDownCircleOutlinedIcon style={{ cursor: 'pointer', color: '#5C5C5C' }} />
                    </HtmlTooltip>
                </Grid>
            </Grid>
            <AlertDialog
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                title={intl.formatMessage({ id: 'logout.confirm-logout' })}
                description={`${intl.formatMessage({ id: 'logout.confirm-description' })}.<br/>${intl.formatMessage({ id: 'logout.please-confirm' })}`}

                confirmAction={logoutMethod}
            />
        </Grid>
    )
}

export default UpperNavbar
