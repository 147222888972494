import axios from 'axios';
import * as apiConst from "../taxmation-consts";

const ADD_COMMENT = apiConst.ADD_COMMENT;
export const addComment = (commentObj: {}) => {
    return axios
        .post(`${process.env.REACT_APP_URL + ADD_COMMENT}`, commentObj,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}
