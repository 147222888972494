import React, { useState } from 'react';
import UserBlackIcon from "../assets/images/icons/navbar/userBlack.png";
import UserBlueIcon from "../assets/images/icons/navbar/userBlue.png";
import taskBlackIcon from "../assets/images/icons/navbar/taskBlack.png";
import taskBlueIcon from "../assets/images/icons/navbar/taskBlue.png";
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GroupIcon from '@mui/icons-material/Group';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';

const SideNavbar = ({ open }: { open: any }) => {

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Users');

    return (

        <List>
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={() => {
                        if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                        else navigate('/users')
                        setActiveTab("Users")
                    }
                    }
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            width: 35,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <img src={activeTab === "Users" ? UserBlueIcon : UserBlackIcon}/>
                    </ListItemIcon>
                    <ListItemText primary={'Users'} sx={{ opacity: open ? 1 : 0 , color:activeTab === "Users" ?"#5D5FEF":"black"}} className='neutral-4 labels' />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={() => {
                        if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/tasks')
                        else navigate('/tasks')
                        setActiveTab("Tasks")
                    }
                    }
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            width: 35,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <img src={activeTab === "Tasks" ? taskBlueIcon : taskBlackIcon} />
                    </ListItemIcon>
                    <ListItemText primary={'Tasks'} sx={{ opacity: open ? 1 : 0, color:activeTab === "Tasks" ?"#5D5FEF":"black" }} className='neutral-4 labels' />
                </ListItemButton>
            </ListItem>
        </List>
    )
}

export default SideNavbar