import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Message from './ui/Message';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AlertDialog = ({ openAlert, setOpenAlert, title, description, confirmAction }: { openAlert: any, setOpenAlert: any, title: string, description: string, confirmAction: any }) => {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpenAlert(true);
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAlert(false);
  };

  useEffect(() => {
    if (openAlert) {
      handleClickOpen();
    }
    else {
      setOpen(false);
    }
  }, [openAlert]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ sx: { borderRadius: "15px", width: '60%' } }}

    >
      <Grid item container direction='column' padding={3} justifyContent='center' rowGap={1}>
        <Grid item container className='neutral-4 labels' fontWeight={400} alignItems='center' justifyContent='space-between'>
          {title}
          <span style={{cursor:'pointer'}} onClick={() => handleClose()}><CloseIcon/></span>
        </Grid>
        <Grid item className='greyLine' width='100%'></Grid>
        <Grid item className='neutral-9 desktop-paragraph-6 labels' fontWeight={500} textAlign='center' paddingTop={1.5}>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Grid>
        <Grid item container direction='row' justifyContent='center' columnGap={2} paddingTop={1.5}>
          <Grid item xs={3}> <button className='greyButton' onClick={() => handleClose()}> <Message id="button.close" className='labelsLinks labels-extra-bold Field-input ' /></button></Grid>
          <Grid item xs={3}> <button className='logoutButton' onClick={() => confirmAction()}><Message id="button.logout" className='labelsLinks labels-extra-bold Field-input '/></button></Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default AlertDialog
