import React, { useState, useEffect, useMemo } from 'react'
import { Box, Grid } from '@mui/material';
import * as TaskServices from '../../../services/task-services.proxy';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import TaskDetailsContainer from '../../../components/Dashboard/ManageTasks/TaskDetailsContainer';
import { useLocation } from 'react-router-dom';
import { getDialogLoadingState, setDialogLoading, setLoading } from '../../../app/slices/loadingSlice';
import { setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { getModifyValue, setModify } from '../../../app/slices/modifySlice';
import * as commentServices from "../../../services/comment-service.proxy";

const TaskDetails = () => {

  const dispatch = useAppDispatch();

  const getTaskById = TaskServices.getTaskById;
  const updateTask = TaskServices.updateTask;
  const getTaskInputData = TaskServices.getTaskInputData;
  const addComment = commentServices.addComment;

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const taskId = query.get("id");

  //dispatched states
  const dialogLoading = useAppSelector(getDialogLoadingState);
  const modify = useAppSelector(getModifyValue);

  const [commentLoading, setCommentLoading] = useState(false);
  const [commentTxt, setCommentTxt] = useState<string>('');


  //data to be filled 
  const [taskDetails, setTaskDetails] = useState<any>();
  const [assigneesList, setAssigneesList] = useState<any>();
  const [clientsList, setClientsList] = useState<any>();
  const [typesList, setTypesList] = useState<any>();
  const [statusesList, setStatusesList] = useState<any>();
  const [historyCategoriesList, setHistoryCategoriesList] = useState<any>();

  const [initiallistLoading, setInitialListLoading] = useState(false);
  const [inputDataLoading, setInputDataLoading] = useState(false);


  const getTaskByIdCall = () => {
    if (!taskId) return;
    setInitialListLoading(true);
    getTaskById(taskId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);
      }
      else {
        let taskObj = {
          id: taskId,
          title: x.title,
          dueDate: moment(x.dueDate, 'M/D/YYYY').format('YYYY-MM-DD'),
          status: x.status,
          type: x.type,
          description: x.description,
          clientId: x.clientId,
          assigneeId: x.assigneeId,
          comments: x.comments,
          toBeTagedUsers: x.toBeTagedUsers,
          history: x.history
        }
        setTaskDetails(taskObj);
        // setTaskDetails(x);
        //  setTaskDetails((prevDetails: any) => ({
        //     ...prevDetails,
        //     dueDate: moment(x.dueDate, 'M/D/YYYY').format('YYYY-MM-DD')
        // }));

      }
      setInitialListLoading(false);
    })

  }

  const getTaskInputDataCall = () => {
    setInputDataLoading(true);
    getTaskInputData().then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);

      }
      else {
        setAssigneesList(x.users);
        setClientsList(x.clients);
        setStatusesList(x.statuses);
        setTypesList(x.types);
        setHistoryCategoriesList(x.historyCategory);
        getTaskByIdCall();

        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 1000);
      }
      setInputDataLoading(false);
    })
  }

  const updateTaskCall = () => {
    dispatch(setLoading(true));
    let taskObj = {
      id: taskId,
      title: taskDetails.title,
      dueDate: taskDetails.dueDate,
      status: taskDetails.status,
      type: taskDetails.type,
      clientId: taskDetails.clientId,
      assigneeId: taskDetails.assigneeId,
      description: taskDetails.description,
    }
    updateTask(taskObj).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 1500);
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setModify(!modify));
        }, 1500);

      }
      dispatch(setLoading(false));
    })
  }


  const addCommentCall = (commentObj: {}) => {
    setCommentLoading(true);
    addComment(commentObj).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 1500);
      }
      else {
        dispatch(setCallStatus('Pass'));
        setCommentTxt('');
        localStorage.removeItem('comment')
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setModify(!modify));
        }, 500);

      }
      setCommentLoading(false)
    })
  }


  useEffect(() => {
    getTaskInputDataCall();
  }, [taskId, modify]);

  return (
    <Grid item container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dialogLoading || initiallistLoading || inputDataLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {statusesList?.length > 0 && assigneesList?.length > 0 && taskDetails?.status && taskDetails?.assigneeId ?
        <TaskDetailsContainer
          taskDetails={taskDetails}
          assigneesList={assigneesList}
          clientsList={clientsList}
          statusesList={statusesList}
          typesList={typesList}
          historyCategoriesList={historyCategoriesList}
          updateTaskCall={updateTaskCall}
          addCommentCall={addCommentCall}
          commentLoading={commentLoading}
          commentTxt={commentTxt}
          setCommentTxt={setCommentTxt}
        />
        :
        <></>
      }

    </Grid>
  )
}

export default TaskDetails