import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Grid, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getLoadingState, setLoading } from '../../../app/slices/loadingSlice';
import { getCallStatus, getErrorMsg, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import DeleteDialog from '../../../sharedComponents/Dialogs/DeleteDialog';
import * as statusesConsts from "../../../status-consts";
import selectedFilter from "../../../assets/images/icons/selectedFilter.png";
import * as userRolesConst from "../../../user-roles-consts";
import { selectRoles } from '../../../app/slices/userDataSlice';
import { useIntl } from "react-intl";
import Message from '../../../sharedComponents/ui/Message';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import orangetask from '../../../assets/images/icons/orangetask.png';
import redtask from '../../../assets/images/icons/redtask.png';
import greentask from '../../../asset/images/icons/greentask.png';
import { useNavigate } from 'react-router-dom';
import classes from "../../../assets/styles/Task.module.css";
import * as TaskServices from '../../../services/task-services.proxy';
import * as StatusesConst from "../../../status-consts";

const updateTask = TaskServices.updateTask;
const deleteTask = TaskServices.deleteTask

export interface TableProps {
  rows: any;
  dataLength: any;
  setDataLength: any;
  modify: any,
  setModify: any,
  statusesList: any,
  typesList: any,
  assigneesList: any,
  clientsList: any
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
  },
}));


const TasksTable = (props: TableProps) => {


  const { rows, modify, setModify, dataLength, setDataLength, statusesList, typesList , assigneesList, clientsList} = props;

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);

  //update role 
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [selectedTask, setSelectedTask] = useState<any>();
  const [selectedTaskToDelete, setselectedTaskToDelete] = useState<any>();

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      width: 10,
      sortable: false,
      renderCell: (params) => (
        <HtmlTooltip
          title={
            <React.Fragment>
              <Grid item container rowGap={1.5} padding={1.5} maxWidth='120px'>

                <Grid item container direction='row' className='neutral-4 labels pointerText' alignItems='center' columnGap={1}
                  onClick={() => {
                    navigate(`/task-details?id=${params.row.id}`)
                    setSelectedTask(() => ({
                      userId: params.row.id,

                    }));

                  }}

                >
                  <EditOutlinedIcon htmlColor='#605e6b' fontSize='small' /><Message id="task.edit" className="Field-input labelsLinks font-weight-400" />
                </Grid>

                <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                  onClick={() => {
                    setselectedTaskToDelete(() => ({
                      id: params.row.id,
                      title: params.row.title
                    }));
                    setOpenDeleteDialog(true);
                  }}
                ><DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /><Message id="ta.delete" className="Field-input labelsLinks font-weight-400" /></Grid>


              </Grid>
            </React.Fragment>
          }
        >
          <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
        </HtmlTooltip>
      ),
    },
    { field: 'title', headerName: intl.formatMessage({ id: 'task.task' }), width: 410 },
    {
      field: 'status', headerName: intl.formatMessage({ id: 'user.status' }), width: 160,
      renderCell: (params) => {
        return (
          <Grid item container direction='row' columnGap={1.5} marginTop={1}>
            <Grid item
              className={classes[
                params.row.status === statusesConsts.NEW_STATUS_ID ? 'new-task' :
                  params.row.status === statusesConsts.OPEN_STATUS_ID ? 'open-task' :
                    params.row.status === statusesConsts.OVERDUE_STATUS_ID ? 'overdue-task' :
                      params.row.status === statusesConsts.IN_PROGRESS_STATUS_ID ? 'inprogress-task' :
                        params.row.status === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? 'completed-task' :
                        params.row.status === statusesConsts.CLOSED_STATUS_ID ? 'closed-task' : ''

              ]}>
              {statusesList?.find((s: any) => s.id === params.row.status)?.name}
              {params.row.status === statusesConsts.OVERDUE_STATUS_ID ?
                <LocalFireDepartmentOutlinedIcon sx={{ fontSize: 18, marginLeft: 0.5 }} />
                :
                <></>
              }
            </Grid>
          </Grid>
        )
      }
    },
    {
      field: 'type', headerName: intl.formatMessage({ id: 'task.type' }), width: 135,
      renderCell: (params) => {
        return (
            <Grid item>
              {typesList?.find((s: any) => s.id === params.row.type)?.name}
            </Grid>
        )
      }
    },
    ...(localStorage.getItem('userType') === '1'  ? [{ field: 'client', headerName: intl.formatMessage({ id: 'task.client' }), width: 137,    renderCell: (params: { row: { clientId: any; }; }) => {
      return (
        <Grid item>
          {clientsList?.find((i:any) => i.id === params.row.clientId)?.name}
        
        </Grid>
      )
    } }] : []),
    {
      field: 'assignee', headerName: intl.formatMessage({ id: 'task.assignee'}), width: 230,
      renderCell: (params) => {
        return (
          <Grid item>
            {assigneesList?.find((i:any) => i.id === params.row.assigneeId).email}
          
          </Grid>
        )
      }
    },
    { field: 'dueDate', headerName: intl.formatMessage({ id: 'task.due-date' }), width: 110 },

    { field: 'module', headerName: intl.formatMessage({ id: 'task.module' }), width: 110 },
    //{ field: 'description', headerName: intl.formatMessage({ id: 'task.description' }), width: 600 },
    { field: 'startdate', headerName: intl.formatMessage({ id: 'task.start-date' }), width: 115 },
    { field: 'relevantperiod', headerName: intl.formatMessage({ id: 'task.relevant-period' }), width: 110 },
 
    { field: 'blockedby', headerName: intl.formatMessage({ id: 'task.blocked-by' }), width: 140 },
    { field: 'blocking', headerName: intl.formatMessage({ id: 'task.blocking' }), width: 140 },
    { field: 'recurringtask', headerName: intl.formatMessage({ id: 'task.recurring-task' }), width: 150 },
    { field: 'recurringperiod', headerName: intl.formatMessage({ id: 'task.recurring-period' }), width: 160 },
    { field: 'priority', headerName: intl.formatMessage({ id: 'task.priority' }), width: 140 },
    { field: 'note', headerName: intl.formatMessage({ id: 'task.note' }), width: 300 }
  ]




  const updateTaskCall = () => {
    dispatch(setLoading(true));
    updateTask({
      userId: selectedTask.userId,
      //payload
    }).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);
      } else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setModify(!modify));
        }, 1000);
      }
      dispatch(setLoading(false));
    });
  };

  const deleteTaskCall = () => {
    dispatch(setLoading(true));
    deleteTask(selectedTaskToDelete.id).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''))
        }, 4500)
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''));
          setOpenDeleteDialog(false);
          dispatch(setModify(!modify))
        }, 1000)
      }
      dispatch(setLoading(false));
    })
  }
  return (
    <div style={{ height: '60vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25]}
        checkboxSelection
        disableColumnMenu
        sx={{
          border: 2,
          borderColor: '#FEFEFF',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
      />
      <DeleteDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        loading={loading}
        callStatus={callStatus}
        errorMsg={errorMsg}
        object={selectedTaskToDelete}
        confirmAction={deleteTaskCall}
        type='task'
      />

    </div>
  )
}

export default TasksTable;