import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from "./app/store"
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios, { Axios } from 'axios';


const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// axios.interceptors.request.use((request) => {
//   return request;
// })

// axios.interceptors.response.use((response) => {
//   return response;
// })

const theme = createTheme({
  typography: {
    fontFamily: 'DM Sans',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'DM Sans';
        }
      `,
    },
  },
});

root.render(
  // <React.StrictMode>
     <BrowserRouter>
    <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <GoogleOAuthProvider clientId='82640514797-3si92pvji47bogctq1p7486rv8d7e4s8.apps.googleusercontent.com' >
      <ThemeProvider theme={theme}>
        <App />
        </ThemeProvider>
      </GoogleOAuthProvider>
      </MsalProvider>
    </Provider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
