
import React, { useState, useCallback, useRef } from "react";
import { atom, RecoilRoot, useRecoilState, useSetRecoilState } from 'recoil';
import {
  Grid,
  ClickAwayListener,
  Fade,
  List,
  ListItemButton,
  Paper,
  Box,
  TextField,
  CssBaseline,
  CircularProgress
} from "@mui/material";
import Message from '../../../sharedComponents/ui/Message';

export interface User {
  id: string;
  email: string;
}

export interface CommentProps {
  taskDetails: {
    id: string;
    toBeTagedUsers: User[];
    comments: any
  };
  addCommentCall: (comment: {}) => void; // Update to take a string
  commentLoading: boolean;
  commentTxt: any;
  setCommentTxt: any;
}

const suggestionsState = atom({
  key: 'BandSuggestionsVisible',
  default: false,
});

const Suggestions: React.FC<{ 
  field: React.RefObject<HTMLTextAreaElement>; 
  value: string; 
  setValue: React.Dispatch<React.SetStateAction<string>>; 
  taskDetails: CommentProps['taskDetails']; 
}> = ({ field, value, setValue, taskDetails }) => {
  const [hasSuggestions, setHasSuggestions] = useRecoilState(suggestionsState);

  const handleClick = useCallback((email: string) => {
    const newValue = value + email + ' ';
    setValue(newValue); // Update state to reflect new value
    setHasSuggestions(false);
    field.current?.focus(); // Focus immediately
  }, [value, setValue, setHasSuggestions, field]);

  const handleClickAway = useCallback(() => {
    setHasSuggestions(false);
  }, [setHasSuggestions]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Fade in={hasSuggestions}>
        <List component={Paper} sx={{ mt: 1 }}>
          {taskDetails?.toBeTagedUsers?.filter((user) => user !== null).map((user) => (
            <ListItemButton key={user.id} onClick={() => handleClick(user.email)}>
              {user.email}
            </ListItemButton>
          ))}
        </List>
      </Fade>
    </ClickAwayListener>
  );
};

const SuggestionsField: React.FC<{ 
  taskDetails: CommentProps['taskDetails']; 
  value: string; 
  setValue: React.Dispatch<React.SetStateAction<string>>;
}> = ({ taskDetails, value, setValue }) => {
  const setHasSuggestions = useSetRecoilState(suggestionsState);
  const textFieldRef = useRef<HTMLTextAreaElement | null>(null);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    setHasSuggestions(newValue.endsWith('@'));
  }, [setHasSuggestions, setValue]);

  return (
    <Box display="inline-block" width="100%" height='50px'>
      <TextField
        fullWidth
        inputRef={textFieldRef}
        multiline
        label="Add Comment (@ to mention)"
        value={value}
        onChange={handleChange}
      />
      <Suggestions field={textFieldRef} value={value} setValue={setValue} taskDetails={taskDetails} />
    </Box>
  );
};


const Messages = (props: CommentProps) => {
  const { taskDetails, addCommentCall, commentLoading,  commentTxt, setCommentTxt  } = props;

  const addTheComment = () => {
    let mentions: string[] = [];
    // if (textFieldRef.current) {
      // let comment = textFieldRef.current.value;
      // let commentTmp = localStorage.getItem('comment');
      taskDetails?.toBeTagedUsers?.filter((c: any) => c !== null)?.map((u: any) => {
        if (commentTxt?.includes("@")) {
          if (commentTxt.includes(u.email))
            mentions.push(u.id)
        }
      })
    // }

    let commentObj = {
      taskId: taskDetails.id,
      content: commentTxt,
      tags: mentions
    }

    // console.log("commentObj", commentObj);

    addCommentCall(commentObj);
  };

  return (
    <Grid item container direction='column' rowGap={2}>
    {/* {!addComment ?
      <Grid item width='150px' onClick={() => setAddComment(true)}><button className='blueButton'>Add Comment</button></Grid>
      :
      <></>} */}
    {/* {addComment ? */}
    <Grid item container direction='column' rowGap={1}>
    <RecoilRoot>
        <CssBaseline />
        <SuggestionsField 
          taskDetails={taskDetails} 
          value={commentTxt} 
          setValue={setCommentTxt} // Pass the setter directly
        />
      </RecoilRoot>

      <Grid item container direction='row' marginTop={3} marginBottom={2} columnGap={1} justifyContent='flex-end'>
        {/* <Grid item width='100px'>
          <button className='greyButton' onClick={() => {
            localStorage.removeItem('comment')}}>Cancel</button>
        </Grid> */}
        <Grid item width='130px' alignSelf='flex-end'>
          <button className='blueButton'
            disabled={commentLoading || !commentTxt}
            onClick={() => addTheComment()}>
            {commentLoading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
              : <Message id="button.save" className='Field-input font-weight-700 labelsLinks' />}
          </button>
        </Grid>
      </Grid>

    </Grid>
    {/* :
      <></>
    } */}
    <Grid item container style={{ overflowY: 'auto', maxHeight: '300px' }} rowGap={2} >
      {taskDetails?.comments?.map((c: any) => (
        <Grid item container direction='column' key={c.id} rowGap={0.5}>
          <Grid item container direction='row' columnGap={2}>
            <Grid item className='labels-extra-bold'>{c.creator}</Grid>
            <Grid item className='neutral-3'>{c.creationDate}</Grid>
          </Grid>
          <Grid item>{c.content}</Grid>
        </Grid>
      ))}
    </Grid>

  </Grid>
  );
};

export default Messages;
